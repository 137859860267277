import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

import { styles } from "../../styles";
import { SectionWrapper } from "../../hoc";
import { slideIn } from "../../utils/motion";
import EarthCanvas from "../../canvas/Earth.js"
import "./Contact.css"


const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_ttxgrs4",
        "template_a52k5pj",
        {
          from_name: form.name,
          to_name: "Jack Storment",
          from_email: form.email,
          to_email: 'stormentjd@gmail.com',
          message: form.message,
        },
        "jB8RtYGb36FkQu3se"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Sent");
        }
      );
  };

  return (
    <div className="containerr">
      <motion.div variants={slideIn("left", "tween", 0.2, 1)} className="form-containerr">
        <p className={styles.sectionSubText}>Get in touch</p>
        <h3 className={styles.sectionHeadText}>Contact form</h3>
        <div className="mt-12 flex flex-col gap-8">
          <label className="label">
            <span className="label-text"></span>
            <input type="text" name="name" value={form.name} onChange={handleChange} placeholder="Name" className="input" />
          </label>
          <label className="label">
            <span className="label-text"></span>
            <input type="email" name="email" value={form.email} onChange={handleChange} placeholder="Email" className="input" />
          </label>
          <label className="label">
            <span className="label-text"></span>
            <textarea rows={7} name="message" value={form.message} onChange={handleChange} placeholder="Message" className="textarea" />
          </label>
          <button className="button" onClick={handleSubmit} >
            {loading ? "Sending..." : "Send"}
          </button>
        </div>
      </motion.div>
      <motion.div variants={slideIn("right", "tween", 0.2, 1)} className="canvas-containerr">
        <EarthCanvas />
      </motion.div>
    </div>
  );
};

export default SectionWrapper(Contact, "contact");